$(function() {
    if ($(window).width() > 1024) {
		$('.js-lg').lightGallery({
		    thumbnail: true,
		    showThumbByDefault: false,
		    currentPagerPosition: 'left',
		    thumbWidth: 100,
		    thumbHeight: '100px',
		    enableThumbDrag: false,
		    pullCaptionUp: false,
		    animateThumb: false
		}); 
	} else {
		$('.js-lg').lightGallery({
		    thumbnail: true,
		    showThumbByDefault: false,
		    // currentPagerPosition: 'left',
		    thumbWidth: 100,
		    thumbHeight: '100px',
		    enableThumbDrag: true,
		    // pullCaptionUp: ,
		    animateThumb: true
		}); 
	}

	function copyUrl() {
		var copyText = document.querySelector('.input-url');

		copyText.select();
		copyText.setSelectionRange(0, 99999); 

		document.execCommand('copy');
	}

	$(document).on('click', '#lg-copy-url', function() {
		var inputUrl = $('.input-url');
		var url = window.location.href;

		inputUrl.val(url);
		copyUrl();
		
		/* $('#modal-copy').modal('show');
		setTimeout(function(){
			$('#modal-copy').modal('hide');
		}, 1500); */
	});

	/* $('.lightgallery-controls--share').on('click', function(e){
		$(this).toggleClass('is-share');
		e.stopPropagation()
	}); */

	$('.js-fullscreen-button').on('click', function(){
		$('.js-article-slider .swiper-slide-active').trigger('click');
	});

})