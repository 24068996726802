$(function(){
    gsap.registerPlugin(ScrollTrigger);

    /* draw lines */
    if ($('.js-line1').length) {
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-line1',
                start: 'top 50%',
                end: '10% top',
                scrub: 1,
            }
        })
        tl.fromTo('.js-line1 .line2', 60, {
            drawSVG: '10% 100%',
        },
        {
            drawSVG: '100% 100%',
            duration: 10,
        })
    }

    if ($('.js-line2').length) {
        const tl2 = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-line2',
                start: 'top 50%',
                end: '10% top',
                scrub: 1,
            }
        })
        tl2.fromTo('.js-line2 .line2', 60, {
            drawSVG: '2% 100%',
        },
        {
            drawSVG: '100% 100%',
            duration: 10,
        })
    }

    if ($('.js-line3').length) {
        const tl3 = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-line3',
                start: 'top 50%',
                end: '10% top',
                scrub: 1,
            }
        })
        tl3.fromTo('.js-line3 .line2', 60, {
            drawSVG: '100% 100%',
        },
        {
            drawSVG: '0% 100%',
            duration: 10,
        })
    }
    
    if ($('.js-line4').length) {
        const tl4 = gsap.timeline({
            scrollTrigger: {
                trigger: '.js-line4',
                start: 'top 50%',
                end: '10% top',
                scrub: 1,
            }
        })
        tl4.fromTo('.js-line4 .line2', 60, {
            drawSVG: '0% 100%',
        },
        {
            drawSVG: '100% 100%',
            duration: 10,
        })
    }

    if ($('.js-step-line').length) {
        $('.js-step-line').each(function(){
            const element = $(this)
            const block = !!element.closest('.block-step')[0] ? element.closest('.block-step')[0] : element.closest('svg').next()[0]

            gsap.from(element, {
                drawSVG: '0%',
                duration: 0.5,
                scrollTrigger: {
                    trigger: block,
                    start: "top 60%",
                    end: "bottom 80%",
                    toggleActions: "play none none none",
                }
            });
        })
    }
    /* draw lines */

    /* product icons fade in/out */
    $('.js-food-icons').each(function(){
        const triggerBlock = $(this)[0]
        const triggerBlockTargets = triggerBlock.querySelectorAll('img')
        const startPoint = $(triggerBlock).hasClass('late-trigger') ? 'top 50%' : 'top 80%'
        const productIcon = gsap.timeline({
            scrollTrigger: {
                trigger: triggerBlock,
                start: startPoint,
                // end: 'bottom 40%',
                end: '+=200',
                scrub: 1,
            }
        })

        productIcon.fromTo(triggerBlockTargets, {
            opacity: '0',
            transform: 'scale(0.8, 0.8)',
        },
        {
            opacity: '1',
            transform: 'scale(1, 1)',
        })
    })
    /* product icons fade in/out */
    
    /* page blocks fade in */
    $('.js-block-fade-in').each(function(){
        const triggerBlock = $(this)[0]
        const productIcon = gsap.timeline({
            scrollTrigger: {
                trigger: triggerBlock,
                start: 'top 60%',
                end: 'bottom 40%',
                scrub: 1,
                onEnter: () => triggerBlock.classList.add('is-in-view')
            }
        })
    })
    /* page blocks fade in */
})
